import "./bootstrap.bundle";

// $(".login-form").on('submit', (event) => {
//     const email = document.getElementById('email');
//     const emailError = document.getElementById('email-error');
//     const password = document.getElementById('password');
//     const passwordError = document.getElementById('password-error');
    
//     if(!email.value)  {
//         emailError.innerHTML = "To pole jest wymagane";
//     }
//     if(!password.value)  {
//         passwordError.innerHTML = "To pole jest wymagane";
//     }

//     if(!password.value && !email.value) {  
//         event.preventDefault();
//     }

// });
$(window).on('load', () => {
    $('#registerData').modal('show');
    $(window).on('shown.bs.modal', () => {
        forceContentToShow('#registerData');
    });

    if($('body').hasClass('should-open-voucher-data')){
        $('#addVoucherData').modal('show');
        forceContentToShow('#addVoucherData');
    }
    $('#add-voucher-popup').on('click', (e) => {
        e.preventDefault();
        $('#addVoucherData').modal('show');
    });
});



function forceContentToShow(element) {
    const parent = $(element).closest('section');
    ScrollReveal().reveal(parent, {
        opacity: 1
    });
}